import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';

const FilesImport = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setFeedbackMessage(null);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleImport = () => {
    if (selectedFile) {
      console.log('import!!');
      setFeedbackMessage(`${selectedFile.name} has been imported successfully!`);
      setTimeout(() => setFeedbackMessage(null), 3000);
      setIsModalOpen(false);
    } else {
      setFeedbackMessage('No file selected! Please choose a file to import.');
      setTimeout(() => setFeedbackMessage(null), 3000);
    }
  };

  return (
    <Box>
      <Button colorScheme="teal" onClick={handleOpenModal}>
        Import
      </Button>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import File</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <p>Select a .csv file to import.</p>
              <Input padding={1} type="file" onChange={handleFileChange} accept=".csv" />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleImport} mr={3}>
              Import
            </Button>
            <Button onClick={handleCloseModal}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {feedbackMessage && (
        <Box
          position="absolute"
          top="10px"
          left="50%"
          transform="translateX(-50%)"
          bg="green.200"
          color="green.800"
          p={2}
          borderRadius="md"
          boxShadow="md"
          zIndex={1000}
        >
          {feedbackMessage}
        </Box>
      )}
    </Box>
  );
};

export default FilesImport;

import React, { useEffect, useState } from 'react';
import ShareSalesTable from './components/ShareSalesTable';
import SortButtonGroup from './components/SortButtonGroup';
import { shareSaleApi } from '../../api/api';

const ShareSales = () => {
  const [shareSalesData, setShareSalesData] = useState<any[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [selectedSort, setSelectedSort] = useState<'createdOn' | 'stateTransitionedOn'>(
    'createdOn',
  );

  const fetchShareSales = async (
    page = 1,
    pageSize = 10,
    sort: 'createdOn' | 'stateTransitionedOn' = 'createdOn',
  ) => {
    try {
      const sortByLatestState = sort === 'stateTransitionedOn';

      const response = await shareSaleApi.listShareSales(page, pageSize, sortByLatestState);
      const { items, pageCount = 1 } = response.data;
      const apiData = items?.map((item: any) => ({
        saleId: item.id,
        contactFullName: item.contactFullName,
        contactEmailAddress: item.contactEmailAddress,
        contactMobileNumber: item.contactMobileNumber,
        createdOn: item.createdOn,
        stateTransitionedOn: item.stateTransitionedOn,
        naturalPersons:
          item.customers?.map((customer: any) => ({
            fullName: customer.fullName,
            emailAddress: customer.emailAddress,
            termsAccepted: customer.tncDetails?.accepted ?? false,
            acceptedAt: customer.tncDetails?.accepted ? customer.tncDetails.acceptedOn : '',
          })) || [],
      }));
      // if (apiData) {
      //   setShareSalesData(apiData);
      // }
      setShareSalesData(apiData || []);

      setTotalCount(pageSize * pageCount);
    } catch (error) {
      console.error('Fetch SharesalesDAta err:', error);
    }
  };

  useEffect(() => {
    fetchShareSales(currentPage, pageSize, selectedSort);
  }, [currentPage, pageSize, selectedSort]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSortChange = (sortType: 'createdOn' | 'stateTransitionedOn') => {
    setSelectedSort(sortType);
    setCurrentPage(1);
  };

  return (
    <div>
      <SortButtonGroup onSortChange={handleSortChange} selectedSort={selectedSort} />
      <ShareSalesTable
        data={shareSalesData}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={totalCount}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default ShareSales;

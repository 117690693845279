import { VStack, Text } from '@chakra-ui/react';
import React from 'react';

interface SubTabMenuProps {
  activeSubTab: 'tradesToBeMade' | 'dailyData' | 'tradesMade';
  setActiveSubTab: (tab: 'tradesToBeMade' | 'dailyData' | 'tradesMade') => void;
}

const SubTabMenu = ({ activeSubTab, setActiveSubTab }: SubTabMenuProps) => {
  return (
    <VStack align="flex-start" paddingLeft={8}>
      <Text
        fontWeight={activeSubTab === 'tradesToBeMade' ? 'bold' : 'normal'}
        color={activeSubTab === 'tradesToBeMade' ? 'teal.500' : 'gray.600'}
        cursor="pointer"
        _hover={{ textDecoration: 'underline', color: 'teal.400' }}
        onClick={() => setActiveSubTab('tradesToBeMade')}
      >
        Trades to be Made
      </Text>
      <Text
        fontWeight={activeSubTab === 'dailyData' ? 'bold' : 'normal'}
        color={activeSubTab === 'dailyData' ? 'teal.500' : 'gray.600'}
        cursor="pointer"
        _hover={{ textDecoration: 'underline', color: 'teal.400' }}
        onClick={() => setActiveSubTab('dailyData')}
      >
        Daily Data
      </Text>
      <Text
        fontWeight={activeSubTab === 'tradesMade' ? 'bold' : 'normal'}
        color={activeSubTab === 'tradesMade' ? 'teal.500' : 'gray.600'}
        cursor="pointer"
        _hover={{ textDecoration: 'underline', color: 'teal.400' }}
        onClick={() => setActiveSubTab('tradesMade')}
      >
        Trades Made
      </Text>
    </VStack>
  );
};

export default SubTabMenu;

import React from 'react';
import { ButtonGroup, Button, Flex, Box, Text, Stack } from '@chakra-ui/react';
// import CustomDatePicker from '../../../Common/CustomDatePicker';

interface SortButtonGroupProps {
  selectedSort: string;
  onSortChange: (sortType: 'createdOn' | 'stateTransitionedOn') => void;
}

const SortButtonGroup = ({ selectedSort, onSortChange }: SortButtonGroupProps) => {
  return (
    <Flex justify="space-between" align="center" mb={4}>
      <Stack>
        <Text fontSize="lg">Sort by</Text>
        <ButtonGroup size="sm" gap={2} isAttached>
          <Button
            onClick={() => onSortChange('createdOn')}
            colorScheme={selectedSort === 'createdOn' ? 'teal' : 'gray'}
          >
            Created On
          </Button>
          <Button
            onClick={() => onSortChange('stateTransitionedOn')}
            colorScheme={selectedSort === 'stateTransitionedOn' ? 'teal' : 'gray'}
          >
            State Updated On
          </Button>
        </ButtonGroup>
      </Stack>

      <Box>{/* <CustomDatePicker /> */}</Box>
    </Flex>
  );
};

export default SortButtonGroup;

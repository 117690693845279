import React from 'react';

import { Button, VStack, Box } from '@chakra-ui/react';

interface TabMenuProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const TabMenu = ({ activeTab, setActiveTab }: TabMenuProps) => {
  return (
    <Box width="200px" padding="4">
      <VStack spacing="4" align="stretch">
        <Button
          variant={activeTab === 'fraudFlags' ? 'solid' : 'outline'}
          colorScheme="teal"
          onClick={() => setActiveTab('fraudFlags')}
        >
          Fraud Flags
        </Button>
        <Button
          variant={activeTab === 'shareSales' ? 'solid' : 'outline'}
          colorScheme="teal"
          onClick={() => setActiveTab('shareSales')}
        >
          Share Sales
        </Button>
        <Button
          variant={activeTab === 'trades' ? 'solid' : 'outline'}
          colorScheme="teal"
          onClick={() => setActiveTab('trades')}
        >
          Trades
        </Button>
      </VStack>
    </Box>
  );
};

export default TabMenu;

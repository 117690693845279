import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Box } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa6';
import Pagination from '../../../Common/Pagination';

interface NaturalPersonProps {
  fullName: string;
  emailAddress: string;
  termsAccepted?: boolean;
  acceptedAt?: string;
}

interface ShareSaleProps {
  saleId: string;
  contactFullName: string;
  contactEmailAddress: string;
  contactMobileNumber: string;
  createdOn: string;
  stateTransitionedOn: string;
  naturalPersons?: NaturalPersonProps[];
}

interface ShareSalesTableProps {
  data: ShareSaleProps[];
  currentPage: number;
  pageSize: number;
  totalCount: number;
  onPageChange: (page: number) => void;
}

const formatDate = (dateString: string) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return date.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

const ShareSalesTable: React.FC<ShareSalesTableProps> = ({
  data,
  currentPage,
  pageSize,
  totalCount,
  onPageChange,
}) => {
  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Sale ID</Th>
            <Th>Full Name</Th>
            <Th>Email Address</Th>
            <Th>Mobile Number</Th>
            <Th>Created On</Th>
            <Th>State Transitioned On</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((sale) => (
            <React.Fragment key={sale.saleId}>
              <Tr>
                <Td>{sale.saleId}</Td>
                <Td>{sale.contactFullName}</Td>
                <Td>{sale.contactEmailAddress}</Td>
                <Td>{sale.contactMobileNumber}</Td>
                <Td>{new Date(sale.createdOn).toLocaleString()}</Td>
                <Td>{new Date(sale.stateTransitionedOn).toLocaleString()}</Td>
              </Tr>
              {sale.naturalPersons && sale.naturalPersons.length > 0 && (
                <Tr>
                  <Td colSpan={6}>
                    <Box pl={4} bg="gray.50" border="1px solid #ccc" borderRadius="md">
                      <Table variant="unstyled" size="sm">
                        <Thead>
                          <Tr>
                            <Th>Natural Person Name</Th>
                            <Th>Natural Person Email</Th>
                            <Th>Terms Accepted</Th>
                            <Th>T&N Accepted At</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {sale.naturalPersons.map((person, index) => (
                            <Tr key={index}>
                              <Td>{person.fullName}</Td>
                              <Td>{person.emailAddress}</Td>
                              <Td>
                                {person.termsAccepted ? (
                                  <FaCheck color="green.200" />
                                ) : (
                                  'waiting...'
                                )}
                              </Td>
                              <Td>
                                {person.termsAccepted && person.acceptedAt
                                  ? formatDate(person.acceptedAt)
                                  : 'N/A'}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                  </Td>
                </Tr>
              )}
            </React.Fragment>
          ))}
        </Tbody>
      </Table>
      <Box padding={2}>
        <Pagination
          currentPage={currentPage}
          pageSize={pageSize}
          totalCount={totalCount}
          onPageChange={onPageChange}
        />
      </Box>
    </TableContainer>
  );
};

export default ShareSalesTable;

import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Checkbox,
  Box,
  Stack,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFraudData, resetPage, setPage } from '../../../../store/fraudDataSlice';
import { AppDispatch, RootState } from '../../../../store';
import { mapToFraudFlagType } from '../../../../utils/mapToFraudFlagType';
import { FraudFlagDto } from '../../../../api/sms-backoffice-api';
import Pagination from '../../../Common/Pagination';
import FraudInfoWindow from '../AddButtonGroup/components/FraudInfoWindow';

import AddButtonGroup from '../AddButtonGroup/AddButtonGroup';
import DeleteButtonGroup from '../DeleteButtonGroup';

const FraudFlagTable: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { items, page, pageSize, pageCount } = useSelector((state: RootState) => state.fraudData);

  const detectionType = useSelector((state: RootState) => state.detectionType.value);

  const [selectedItem, setSelectedItem] = useState<FraudFlagDto | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  // const [selectAllChecked, setSelectAllChecked] = useState(false);

  const clearSelection = () => {
    setSelectedIds([]);
    // setSelectAllChecked(false);
  };

  const fraudFlagType = mapToFraudFlagType(detectionType);

  useEffect(() => {
    if (fraudFlagType) {
      dispatch(resetPage());
      dispatch(fetchFraudData({ fraudFlagType, page: 1, pageSize }));
      clearSelection();
    } else {
      console.error('Invalid detection type');
    }
  }, [detectionType, dispatch, pageSize, fraudFlagType]);

  const handlePageChange = (newPage: number) => {
    if (fraudFlagType) {
      dispatch(setPage(newPage));
      dispatch(fetchFraudData({ fraudFlagType, page: newPage, pageSize }));
    } else {
      console.error('Invalid detection type');
    }
  };

  const handleCheckboxChange = (id: string) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id],
    );
  };

  // const handleSelectAll = () => {
  //   if (selectAllChecked) {
  //     setSelectedIds([]);
  //   } else {
  //     const allIds = items.map((item) => item.id || '');
  //     setSelectedIds(allIds);
  //   }
  //   setSelectAllChecked(!selectAllChecked);
  // };

  const handleItemClick = (item: FraudFlagDto) => {
    setSelectedItem(item);
    onOpen();
  };

  // useEffect(() => {
  //   setSelectAllChecked(items.every((item) => selectedIds.includes(item.id || '')));
  // }, [items, selectedIds]);

  const truncateNote = (note: string | undefined) => {
    return note && note.length > 25 ? `${note.slice(0, 25)}...` : note || 'No note available';
  };

  return (
    <Box>
      <TableContainer>
        <Table variant="striped" colorScheme="orange" width="980px">
          <Thead>
            <Tr>
              {detectionType === 'BANK_DETAILS' ? (
                <>
                  <Th fontSize="md" width="200px">
                    BSB Number
                  </Th>
                  <Th fontSize="md" width="200px">
                    Account Number
                  </Th>
                </>
              ) : (
                <Th fontSize="md" width="400px">
                  {detectionType.replace(/_/g, ' ')}
                </Th>
              )}
              <Th fontSize="md" width="300px">
                Note
              </Th>
              <Th fontSize="md" width="300px">
                Update By
              </Th>
              <Th isNumeric fontSize="md" width="100px">
                Select to Delete
                {/* <Checkbox
                  border="grey"
                  ml={2}
                  isChecked={selectAllChecked}
                  onChange={handleSelectAll}
                /> */}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item) => (
              <Tr key={item.id}>
                {detectionType === 'BANK_DETAILS' ? (
                  <>
                    <Td onClick={() => handleItemClick(item)} _hover={{ cursor: 'pointer' }}>
                      {item.value}
                    </Td>
                    <Td onClick={() => handleItemClick(item)} _hover={{ cursor: 'pointer' }}>
                      {item.value2}
                    </Td>
                  </>
                ) : (
                  <Td onClick={() => handleItemClick(item)} _hover={{ cursor: 'pointer' }}>
                    {item.value}
                  </Td>
                )}
                <Td onClick={() => handleItemClick(item)} _hover={{ cursor: 'pointer' }}>
                  {truncateNote(item.note ?? '')}
                </Td>
                <Td onClick={() => handleItemClick(item)} _hover={{ cursor: 'pointer' }}>
                  {truncateNote(item.updatedBy ?? '')}
                </Td>
                <Td isNumeric>
                  <Checkbox
                    border="grey"
                    isChecked={selectedIds.includes(item.id || '')}
                    onChange={() => handleCheckboxChange(item.id || '')}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Flex
        direction="row"
        justifyContent="space-between"
        marginY="8px"
        alignItems="center"
        width="980px"
      >
        <Box flex="0 0 60%">
          <Pagination
            currentPage={page}
            totalCount={pageSize * pageCount}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </Box>
        <Stack direction="row" spacing="18px" justify="flex-end" flex="0 0 auto">
          <AddButtonGroup
            onSave={() => {
              if (fraudFlagType) {
                dispatch(fetchFraudData({ fraudFlagType, page: 1, pageSize }));
              }
            }}
          />
          <DeleteButtonGroup
            selectedIds={selectedIds}
            detectionType={fraudFlagType!}
            clearSelection={clearSelection}
          />
        </Stack>
      </Flex>

      {selectedItem && (
        <FraudInfoWindow
          isOpen={isOpen}
          onClose={onClose}
          item={selectedItem}
          onSave={() => {
            if (fraudFlagType) {
              dispatch(fetchFraudData({ fraudFlagType, page: 1, pageSize }));
            }
          }}
        />
      )}
    </Box>
  );
};

export default FraudFlagTable;

import React from 'react';
import { Button, Box, HStack } from '@chakra-ui/react';

interface PaginationProps {
  currentPage: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
}) => {
  const pageCount = Math.ceil(totalCount / pageSize);

  const getPaginationRange = () => {
    const totalNumbers = 5;
    const siblingCount = 1;

    if (pageCount <= totalNumbers) {
      return Array.from({ length: pageCount }, (_, num) => num + 1);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, pageCount);

    const showLeftDots = leftSiblingIndex > 2;
    const showRightDots = rightSiblingIndex < pageCount - 1;

    const firstPageIndex = 1;
    const lastPageIndex = pageCount;

    const range: (number | string)[] = [];

    if (!showLeftDots && showRightDots) {
      const leftRange = Array.from({ length: 3 }, (_, num) => num + 1);
      range.push(...leftRange, '...', lastPageIndex);
    } else if (showLeftDots && !showRightDots) {
      const rightRange = Array.from({ length: 3 }, (_, num) => pageCount - 2 + num);
      range.push(firstPageIndex, '...', ...rightRange);
    } else if (showLeftDots && showRightDots) {
      range.push(
        firstPageIndex,
        '...',
        ...Array.from({ length: 3 }, (_, num) => leftSiblingIndex + num),
        '...',
        lastPageIndex,
      );
    }

    return range;
  };

  const paginationRange = getPaginationRange();

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Button
        onClick={() => onPageChange(Math.max(1, currentPage - 1))}
        disabled={currentPage <= 1}
        size="sm"
      >
        {'<'}
      </Button>

      <HStack>
        {paginationRange.map((page, index) => {
          if (typeof page === 'string') {
            return <Box key={index}>...</Box>;
          }

          return (
            <Button
              key={index}
              onClick={() => onPageChange(page)}
              colorScheme={page === currentPage ? 'teal' : 'gray'}
              size="sm"
            >
              {page}
            </Button>
          );
        })}
      </HStack>

      <Button
        onClick={() => onPageChange(Math.min(pageCount, currentPage + 1))}
        disabled={currentPage >= pageCount}
        size="sm"
      >
        {'>'}
      </Button>
    </Box>
  );
};

export default Pagination;

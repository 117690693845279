import React, { useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Box, HStack } from '@chakra-ui/react';
import Pagination from '../../../Common/Pagination';
import CustomDatePicker from '../../../Common/CustomDatePicker';
import FilesImport from './Components/FilesImport';

const TradesMade = () => {
  const data = [
    {
      iosName: 'IOS123',
      accountCode: 'Acc123123',
      securityCode: 'Sec123123',
      buySell: 'Buy',
      volume: 50000,
      price: 1,
      price$$: 1,
      value: 50000,
      saleDate: '30/11/2024',
    },
    {
      iosName: 'IOS234',
      accountCode: 'Acc234234',
      securityCode: 'Sec234234',
      buySell: 'Sell',
      volume: 20000,
      price: 1,
      price$$: 1,
      value: 20000,
      saleDate: '01/12/2024',
    },
    {
      iosName: 'IOS123',
      accountCode: 'Acc123123',
      securityCode: 'Sec123123',
      buySell: 'Buy',
      volume: 50000,
      price: 1,
      price$$: 1,
      value: 50000,
      saleDate: '30/11/2024',
    },
    {
      iosName: 'IOS234',
      accountCode: 'Acc234234',
      securityCode: 'Sec234234',
      buySell: 'Sell',
      volume: 20000,
      price: 1,
      price$$: 1,
      value: 20000,
      saleDate: '01/12/2024',
    },
    {
      iosName: 'IOS123',
      accountCode: 'Acc123123',
      securityCode: 'Sec123123',
      buySell: 'Buy',
      volume: 50000,
      price: 1,
      price$$: 1,
      value: 50000,
      saleDate: '30/11/2024',
    },
    {
      iosName: 'IOS234',
      accountCode: 'Acc234234',
      securityCode: 'Sec234234',
      buySell: 'Sell',
      volume: 20000,
      price: 1,
      price$$: 1,
      value: 20000,
      saleDate: '01/12/2024',
    },
    {
      iosName: 'IOS123',
      accountCode: 'Acc123123',
      securityCode: 'Sec123123',
      buySell: 'Buy',
      volume: 50000,
      price: 1,
      price$$: 1,
      value: 50000,
      saleDate: '30/11/2024',
    },
    {
      iosName: 'IOS234',
      accountCode: 'Acc234234',
      securityCode: 'Sec234234',
      buySell: 'Sell',
      volume: 20000,
      price: 1,
      price$$: 1,
      value: 20000,
      saleDate: '01/12/2024',
    },
    {
      iosName: 'IOS123',
      accountCode: 'Acc123123',
      securityCode: 'Sec123123',
      buySell: 'Buy',
      volume: 50000,
      price: 1,
      price$$: 1,
      value: 50000,
      saleDate: '30/11/2024',
    },
    {
      iosName: 'IOS234',
      accountCode: 'Acc234234',
      securityCode: 'Sec234234',
      buySell: 'Sell',
      volume: 20000,
      price: 1,
      price$$: 1,
      value: 20000,
      saleDate: '01/12/2024',
    },
    {
      iosName: 'IOS123',
      accountCode: 'Acc123123',
      securityCode: 'Sec123123',
      buySell: 'Buy',
      volume: 50000,
      price: 1,
      price$$: 1,
      value: 50000,
      saleDate: '30/11/2024',
    },
    {
      iosName: 'IOS234',
      accountCode: 'Acc234234',
      securityCode: 'Sec234234',
      buySell: 'Sell',
      volume: 20000,
      price: 1,
      price$$: 1,
      value: 20000,
      saleDate: '01/12/2024',
    },
    {
      iosName: 'IOS123',
      accountCode: 'Acc123123',
      securityCode: 'Sec123123',
      buySell: 'Buy',
      volume: 50000,
      price: 1,
      price$$: 1,
      value: 50000,
      saleDate: '30/11/2024',
    },
    {
      iosName: 'IOS234',
      accountCode: 'Acc234234',
      securityCode: 'Sec234234',
      buySell: 'Sell',
      volume: 20000,
      price: 1,
      price$$: 1,
      value: 20000,
      saleDate: '01/12/2024',
    },
  ];

  const [filteredData, setFilteredData] = useState(data);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const applyDateFilter = () => {
    if (selectedDate) {
      const formattedDate = selectedDate.toLocaleDateString('en-GB');

      const filtered = data.filter((trade) => trade.saleDate === formattedDate);
      setFilteredData(filtered);
      setCurrentPage(1);
    } else {
      setFilteredData(data);
      setCurrentPage(1);
    }
  };

  const clearDateFilter = () => {
    setSelectedDate(null);
    setFilteredData(data);
    setCurrentPage(1);
  };

  const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <Box>
      <HStack alignItems="start">
        <CustomDatePicker
          onDateChange={handleDateChange}
          onApply={applyDateFilter}
          onClear={clearDateFilter}
        />
        <FilesImport />
      </HStack>
      <Box marginY={4}>
        <TableContainer
          overflowX="auto"
          maxWidth="1400px"
          border="1px solid #e2e8f0"
          borderRadius="md"
        >
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>IOS Name</Th>
                <Th>Account Code</Th>
                <Th>Security Code</Th>
                <Th>Buy/Sell</Th>
                <Th>Volume</Th>
                <Th>Price</Th>
                <Th>Price $$</Th>
                <Th>Value</Th>
                <Th>Sale Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {paginatedData.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.iosName}</Td>
                  <Td>{item.accountCode}</Td>
                  <Td>{item.securityCode}</Td>
                  <Td>{item.buySell}</Td>
                  <Td>{item.volume}</Td>
                  <Td>{item.price}</Td>
                  <Td>{item.price$$}</Td>
                  <Td>{item.value}</Td>
                  <Td>{item.saleDate}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Pagination
        currentPage={currentPage}
        totalCount={filteredData.length}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
      />
    </Box>
  );
};

export default TradesMade;

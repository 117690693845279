import React, { useState } from 'react';
import { Text } from '@chakra-ui/react';
import FraudInfoWindow from './components/AddButtonGroup/components/FraudInfoWindow';
import { useDispatch } from 'react-redux';
import { FraudFlagDto } from '../../api/sms-backoffice-api';
import { setDetectionType } from '../../store/detectionTypeSlice';
import DetectionTypeSwitch from './components/DetectionTypeSwitch';
import FraudFlagTable from './components/FraudFlagTable';

const FraudFlags = () => {
  const [currentItem] = useState<FraudFlagDto | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleSave = (newDetectionType: string) => {
    dispatch(setDetectionType(newDetectionType.replace(/\s+/g, '_')));
    setIsModalOpen(false);
  };

  return (
    <div>
      <Text fontSize="lg">Detection Type</Text>
      <DetectionTypeSwitch />

      <FraudFlagTable />

      {currentItem && (
        <FraudInfoWindow
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          item={currentItem}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default FraudFlags;

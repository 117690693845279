import { Box } from '@chakra-ui/react';

import TradesToBeMade from './Components/TradesToBeMade';
import DailyData from './Components/DailyData';
import TradesMade from './Components/TradesMade';

interface TradesProps {
  activeSubTab: 'tradesToBeMade' | 'dailyData' | 'tradesMade';
}

const Trades = ({ activeSubTab }: TradesProps) => {
  return (
    <Box>
      {activeSubTab === 'tradesToBeMade' && <TradesToBeMade />}
      {activeSubTab === 'dailyData' && <DailyData />}
      {activeSubTab === 'tradesMade' && <TradesMade />}
    </Box>
  );
};

export default Trades;

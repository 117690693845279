import Login from '../../components/Common/Login';

const Auth = () => {
  return (
    <>
      <Login />
    </>
  );
};

export default Auth;

import { Button, HStack, Input, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

interface CustomDatePickerProps {
  onDateChange: (date: Date | null) => void;
  onApply: () => void;
  onClear: () => void;
}

const CustomDatePicker = ({ onDateChange, onApply, onClear }: CustomDatePickerProps) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());

  useEffect(() => {
    if (startDate) {
      onDateChange(startDate);
    }
  }, [startDate, onDateChange]);

  const handleDateChange = (date: Date | null) => {
    setStartDate(date);
    onDateChange(date);
  };

  return (
    <Stack width="fit-content" p={2} borderWidth="1px" borderRadius="md" boxShadow="sm">
      <DatePicker
        selected={startDate}
        onChange={handleDateChange}
        customInput={<Input variant="outline" placeholder="Select date" />}
        dateFormat="dd/MM/yyyy"
      />
      <HStack justifyContent="space-evenly">
        <Button colorScheme="teal" onClick={onApply}>
          Apply
        </Button>
        <Button onClick={onClear}>Clear</Button>
      </HStack>
    </Stack>
  );
};

export default CustomDatePicker;

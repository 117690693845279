import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  useToast,
} from '@chakra-ui/react';

import FraudInfoInput from './components/FraudInfoInputGroup/components/FraudInfoInput';
import { mapToFraudFlagType } from '../../../../../../utils/mapToFraudFlagType';
import { FraudFlagDto } from '../../../../../../api/sms-backoffice-api';
import { fraudFlagsApi } from '../../../../../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFraudData } from '../../../../../../store/fraudDataSlice';
import { AppDispatch, RootState } from '../../../../../../store';

interface FraudInfoWindowProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (newDetectionType: string) => void;
  item?: FraudFlagDto;
}

const FraudInfoWindow: React.FC<FraudInfoWindowProps> = ({ isOpen, onClose, onSave, item }) => {
  const [value, setValue] = useState<string>('');
  const [value2, setValue2] = useState<string>('');
  const [note, setNote] = useState<string>('');

  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const detectionType = useSelector((state: RootState) => state.detectionType.value);

  useEffect(() => {
    if (isOpen && item) {
      setValue(item.value || '');
      setValue2(item.value2 || '');
      setNote(item.note || '');
    } else {
      setValue('');
      setValue2('');
      setNote('');
    }
  }, [isOpen, item]);

  const handleSave = async () => {
    const mappedType = mapToFraudFlagType(detectionType);
    if (!mappedType) {
      toast({
        title: 'Error',
        description: 'Invalid detection type selected',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const dataToSubmit: FraudFlagDto = {
      id: item?.id, //check id
      value,
      value2: detectionType === 'BANK_DETAILS' ? value2 : undefined,
      note,
    };

    try {
      if (item?.id) {
        await fraudFlagsApi.updateFraudFlag(mappedType, item.id, dataToSubmit);
        toast({
          title: 'Success',
          description: 'Data successfully updated',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        await fraudFlagsApi.createFraudFlag(mappedType, dataToSubmit);
        toast({
          title: 'Success',
          description: 'Data successfully created',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }

      dispatch(
        fetchFraudData({
          fraudFlagType: mappedType,
          page: 1,
          pageSize: 10,
        }),
      );

      onSave(detectionType);
      onClose();
    } catch (error) {
      console.error('Error saving data:', error);
      toast({
        title: 'Error',
        description: 'Failed to save data',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="600px">
        <ModalHeader>{item?.id ? 'Update an existing Fraud' : 'Add a new Fraud'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl mt={4}>
            <FraudInfoInput
              detectionType={detectionType}
              value={value}
              setValue={setValue}
              value2={value2}
              setValue2={setValue2}
              note={note}
              setNote={setNote}
              item={item}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSave}>
            Save
          </Button>
          <Button onClick={onClose} ml={3}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FraudInfoWindow;
